import { paths } from "@paths";
import { asLink } from "@prismicio/client";
import { SliceZone } from "@prismicio/react";
import { components } from "@slicesComponents";
import { NextPage } from "next";
import { OrganizationJsonLd, SiteLinksSearchBoxJsonLd } from "next-seo";
import { HomePageDocument } from "prismicio-types";
import React, { useMemo, useRef } from "react";

import { Seo } from "@components/Seo";
import {
  commonLinks,
  companyHouseLink,
  organizationJson,
  publicUrl,
  searchQueryParam,
} from "@config";
import { useRouter } from "@hooks/useRouter";
import { useSeo } from "@hooks/useSeo";
import { PrismicPageProps } from "@integrations/prismic/types";
import { homeDefaults } from "@messages/seo";
import { useGlobalConfigStore } from "@stores/useGlobalConfigStore";

import * as S from "./styles";

export type HomePageProps = PrismicPageProps<HomePageDocument>;

const platforms = ["spotify", "twitter", "instagram", "facebook"];

export const HomeView: NextPage<HomePageProps> = ({ page: { data } }) => {
  const { slices, meta_description, meta_title } = data || {};
  const { locale } = useRouter();
  const { footer } = useGlobalConfigStore();

  const { social_links } = footer?.data || {};
  const collectionsRef = useRef<HTMLDivElement>(null);

  const localeLinks = useMemo(
    () =>
      social_links?.reduce((acc, { name, link }) => {
        if (
          name &&
          asLink(link) !== null &&
          platforms.includes(name.toLowerCase()) &&
          link
        ) {
          acc.push(asLink(link) as string);
        }
        return acc;
      }, [] as string[]) || [],
    [locale, social_links]
  );

  const sameAsLinks = [companyHouseLink, ...localeLinks, ...commonLinks];

  const { title, description } = useSeo(
    "home",
    {
      description: homeDefaults.description,
      title: homeDefaults.title,
    },
    {
      description: meta_description || "",
      title: meta_title || "",
    }
  );

  return (
    <S.ContentWrapper>
      <Seo withHreflang description={description} title={title} />
      <SiteLinksSearchBoxJsonLd
        potentialActions={[
          {
            queryInput: "search_term_string",
            target: `${publicUrl}/${locale}${paths.search}?${searchQueryParam}`,
          },
        ]}
        url={publicUrl}
      />
      <OrganizationJsonLd
        {...organizationJson}
        sameAs={sameAsLinks}
        url={publicUrl}
      />
      <div ref={collectionsRef} />
      <SliceZone components={components} slices={slices} />
    </S.ContentWrapper>
  );
};
