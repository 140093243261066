import { getCountryFromRequest, getLegacyRedirectUrl } from "@ssr/helpers";
import { IncomingMessage } from "http";
import { GetServerSideProps } from "next";

import { serverSideCaching } from "@config";
import { getHomePage } from "@integrations/prismic/api";
import { getChannel } from "@providers/ConfigProvider/helpers";
import {
  getLocaleForCountry,
  isSupportedLocale,
} from "@providers/TranslationProvider/helpers";
import { HomeView } from "@views/HomeView";

export default HomeView;

const getDefaultLocale = (req: IncomingMessage) => {
  const country = getCountryFromRequest(req);
  return getLocaleForCountry(country);
};

const defaultProps = {
  page: {},
  seoData: {
    withHreflang: false,
  },
};

export const getServerSideProps: GetServerSideProps = async ({
  locale: localeString,
  query,
  req,
  res,
  previewData,
}) => {
  const supportedLocale = isSupportedLocale(localeString);
  const locale = supportedLocale ? localeString : getDefaultLocale(req);

  if (!locale) {
    return { notFound: true, props: defaultProps };
  }

  if (query?.urlParts && [1, 2, 3].includes(query.urlParts.length)) {
    /**
     * We only handle urls containing 1-3 parts like:
     * -  /bb-king
     * - /korn/un-touchables
     * - /korn/un-touchables/lp-x2
     */
    const url = await getLegacyRedirectUrl(
      getChannel(locale),
      query.urlParts as string[]
    );

    const redirectProps = url
      ? { redirect: { destination: `/${locale}${url}`, permanent: true } }
      : { notFound: true };

    return {
      props: defaultProps,
      ...redirectProps,
    };
  }

  const redirect = supportedLocale ? undefined : { destination: `/${locale}` };
  const page = await getHomePage({ lang: locale, previewData });

  if (!page) {
    return { notFound: true, props: defaultProps, redirect: undefined };
  }

  res.setHeader("Cache-Control", serverSideCaching());

  return {
    notFound: false,
    props: {
      page,
      seoData: {
        withHreflang: true,
      },
    },
    redirect,
  };
};
