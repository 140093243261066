import { useIntl } from "react-intl";

import {
  ToggleURLParams,
  URLFilterMapping,
} from "@components/ProductFilters/constants";
import {
  Filters,
  ListingParam,
  ToggleParams,
} from "@components/ProductFilters/types";
import { searchQueryParam } from "@config";
import { seoDescription, seoTitle } from "@messages/seo";
import { ListingType } from "@providers/ListingProvider";
import { capitalize } from "@utils/misc";

import { CustomNextRouter } from "./useRouter";

interface SearchListingReturn {
  type: string;
  value?: string;
}

export type CustomQuery = {
  ParsedUrlQuery?: Pick<CustomNextRouter, "query">;
  filters?: Locale;
  [searchQueryParam]?: string;
};

export const getSearchListingType = (
  listingType: ListingType,
  query: CustomQuery
): SearchListingReturn => {
  const filters = query?.filters;

  if (listingType && listingType !== "search") {
    const basicValue =
      (Object.values(query)?.[0] as unknown as string)?.replaceAll("-", " ") ||
      "";

    return {
      type: capitalize(listingType as any),
      value: basicValue,
    };
  }

  if (Array.isArray(filters) && filters?.length) {
    const param = filters?.[0];
    const filterValue = filters?.[1];

    if (ToggleURLParams.includes(param as ToggleParams)) {
      const urlParam = URLFilterMapping[param as ListingParam] as keyof Filters;

      return {
        type: capitalize(urlParam),
        value: filterValue,
      };
    }

    return {
      type: capitalize(param),
      value: filterValue,
    };
  }

  return {
    type: "Default",
    value: query?.[searchQueryParam],
  };
};

export const useSeo = (
  selector: string,
  values = {},
  externalValues?: { description?: string; title?: string }
) => {
  const { formatMessage } = useIntl();
  const { description: externalDescription, title: externalTitle } =
    externalValues || {};

  const title = seoTitle?.[selector];
  const description = seoDescription?.[selector];

  return {
    description:
      externalDescription ||
      (description ? formatMessage(description, values) : description),
    title: externalTitle || (title ? formatMessage(title, values) : title),
  };
};
