import { defineMessages, MessageDescriptor } from "react-intl";

export const clubDefaults = {
  description:
    "A limited edition Rough Trade Exclusive vinyl pressing of our hand-picked Album of the Month to your door, every month, along with other perks only available to club members.",
  title: "A new album delivered to you every month",
};

export const homeDefaults = {
  description:
    "Buy and pre-order vinyl records, books + merchandise online from Rough Trade, independent purveyors of great music, since 1976.",
  title: "Rough Trade - Independent Record Store | Vinyl, CDs, Books +",
};

export const seoTitle = defineMessages<string, MessageDescriptor>({
  accountAddress: {
    defaultMessage: `Your address book | Rough Trade`,
    id: "1QTlKX",
  },
  accountConfirm: {
    defaultMessage: `Confirm your account | Rough Trade`,
    id: "LvFScX",
  },
  accountFavourites: {
    defaultMessage: `Your favourites | Rough Trade`,
    id: "yXkTYv",
  },
  accountOrder: {
    defaultMessage: `Manage your orders | Rough Trade`,
    id: "T6hemY",
  },
  accountPaymentMethods: {
    defaultMessage: `Your payment methods | Rough Trade`,
    id: "jgrsSv",
  },
  accountProfile: {
    defaultMessage: `Your account profile | Rough Trade`,
    id: "xm/Y9U",
  },
  checkout: {
    defaultMessage: `Checkout | Rough Trade`,
    id: "QQNI8r",
  },
  club: {
    defaultMessage: "{title} | Rough Trade Club",
    id: "eBHDat",
  },
  events: {
    defaultMessage: "See all upcoming live events hosted {message}",
    id: "gf8j6d",
  },
  home: {
    defaultMessage: `Rough Trade - Independent Record Store | Vinyl, CDs, Books +`,
    id: "La9c+g",
  },
  privacy: {
    defaultMessage: `Our privacy policy | Rough Trade`,
    id: "2v+0Sr",
  },
  searchCollection: {
    defaultMessage: `{value} | Rough Trade`,
    id: "jUqWSa",
  },
  searchDefault: {
    defaultMessage: `Search results for {value} | Rough Trade`,
    id: "0jjRdi",
  },
  searchExclusive: {
    defaultMessage:
      "Exclusive vinyl, books & merch only available at Rough Trade",
    id: "aNJN74",
  },
  searchGenre: {
    defaultMessage: `Discover {value} vinyl records, CDs and Books | Rough Trade`,
    id: "X6eiB5",
  },
  searchLimited: {
    defaultMessage: `Buy limited edition vinyl records and books | Rough Trade`,
    id: "7aNGPu",
  },
  searchPreorders: {
    defaultMessage: `Pre-order vinyl records, books and merchandise online | Rough Trade`,
    id: "631QEu",
  },
  searchSale: {
    defaultMessage: `Vinyl records and books on sale | Rough Trade`,
    id: "Rn+LjK",
  },
  searchSigned: {
    defaultMessage: `Buy signed vinyl records and books online | Rough Trade`,
    id: "FJN4RX",
  },
  storePage: {
    defaultMessage: `{title} | Our Stores | Rough Trade`,
    id: "WgKjxt",
  },
  storesLanding: {
    defaultMessage: `{title} | Rough Trade`,
    id: "voqP55",
  },
  terms: {
    defaultMessage: `Our terms and conditions | Rough Trade`,
    id: "48um7y",
  },
});

export const seoDescription = defineMessages<string, MessageDescriptor>({
  accountAddress: {
    defaultMessage: `Manage and track all your saved addresses for Rough Trade, independent purveyors of great music, since 1976.`,
    id: "9NCymY",
  },
  accountConfirm: {
    defaultMessage: `Buy and pre-order vinyl records, books + merchandise online from Rough Trade, independent purveyors of great music, since 1976.`,
    id: "U+SYLW",
  },
  accountFavourites: {
    defaultMessage: `Manage and track all your favourited collections and products from Rough Trade, independent purveyors of great music, since 1976.`,
    id: "xjhN3X",
  },
  accountOrder: {
    defaultMessage:
      "Manage and track all your orders and pre-orders from Rough Trade.",
    id: "qe+XQi",
  },
  accountPaymentMethods: {
    defaultMessage: `Manage all your payment methods for Rough Trade, independent purveyors of great music, since 1976.`,
    id: "QXDn2O",
  },
  accountProfile: {
    defaultMessage: `Manage your account profile for Rough Trade, independent purveyors of great music, since 1976.`,
    id: "I7evTx",
  },
  checkout: {
    defaultMessage: `Buy and pre-order vinyl records, books + merchandise online from Rough Trade, independent purveyors of great music, since 1976.`,
    id: "U+SYLW",
  },
  club: {
    defaultMessage: "{description}",
    id: "JPGSJT",
  },
  events: {
    defaultMessage:
      "Up close and personal events with you favourite stars. Live in-stores, signings and in-conversations hosted at our stores or in hand-picked venues.",
    id: "hr04l8",
  },
  home: {
    defaultMessage: `Buy and pre-order vinyl records, books + merchandise online from Rough Trade, independent purveyors of great music, since 1976.`,
    id: "U+SYLW",
  },
  privacy: {
    defaultMessage:
      "We are committed to protecting your privacy and security and will process personal information in accordance with GDPR and any other applicable privacy laws.",
    id: "RRcjiS",
  },
  searchCollection: {
    defaultMessage: `Buy and pre-order vinyl records, books + merchandise online from Rough Trade, independent purveyors of great music, since 1976.`,
    id: "U+SYLW",
  },
  searchDefault: {
    defaultMessage: `Buy and pre-order vinyl records, books + merchandise online from Rough Trade, independent purveyors of great music, since 1976.`,
    id: "U+SYLW",
  },
  searchExclusive: {
    defaultMessage:
      "Coloured vinyl variants, signed editions and plenty of bonus material that you can't find anywhere else. ",
    id: "+HIftf",
  },
  searchGenre: {
    defaultMessage: `Buy and pre-order vinyl records, books + merchandise online from Rough Trade, independent purveyors of great music, since 1976.`,
    id: "U+SYLW",
  },
  searchLimited: {
    defaultMessage: `Buy limited edition vinyl records, books and merchandise from Rough Trade, independent purveyors of great music, since 1976.`,
    id: "TrE24L",
  },
  searchPreorders: {
    defaultMessage: `Pre-order the newest vinyl records, books and merchandise from Rough Trade, independent purveyors of great music, since 1976.`,
    id: "PyOVgc",
  },
  searchSale: {
    defaultMessage:
      "Our latest offers on vinyl records, books and merchandise at Rough Trade, independent purveyors of independent purveyors of great music, since 1976.",
    id: "piKBkd",
  },
  searchSigned: {
    defaultMessage: `Buy and pre-order signed vinyl records and books from Rough Trade, independent purveyors of great music, since 1976.`,
    id: "SeZW4B",
  },
  storePage: {
    defaultMessage: `Opening hours, upcoming events, contact details and directions for our {title} store.`,
    id: "ymrAfZ",
  },
  storesLanding: {
    defaultMessage: `{description} Rough Trade, independent purveyors of independent purveyors of great music, since 1976.`,
    id: "OFE3Tg",
  },
  terms: {
    defaultMessage:
      "Discover the full terms and conditions for using Rough Trade. Detailed information on the terms and conditions for using Rough Trade.",
    id: "jD7Im1",
  },
});
